<template>
  <div class="ticketWrap">
    <div class="titleWrap">
      <img :src="require('../../assets/image/ticket/icon.png')" alt />
      <span class="title">票价信息</span>
    </div>
    <div class="contentWrap">
      <img :src="baseUrl + dataInfo.photo" alt />
      <!-- <img :src="require('../../assets/image/ticket/main.png')" alt /> -->
      <div class="btn trans" @click="goLocation">立即预订</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";
import mx_utils from "@/mixins/mx_utils";

export default {
  data() {
    return {
      dataInfo: {}
    };
  },
  mixins: [mx_utils],
  mounted() {
    this.getData(config.ticketUrl);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData[0];
          }
          // console.log("res.data.data:", this.dataInfo);
        }
      } catch (e) {
        console.log("res err:", e);
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.ticketWrap {
  padding-top: 60px;
  margin: 0px 60px 78px 60px;
  .titleWrap {
    position: relative;
    width: 1219px;
    height: 82px;
    background: $main-bgOrange;
    box-sizing: border-box;
    margin-left: 60px;
    margin-bottom: 17px;
    border-top-right-radius: 100px;
    .title {
      font-size: 31px;
      line-height: 82px;
      margin-left: 100px;
      margin-left: 120px;
    }
    > img {
      position: absolute;
      top: -39px;
      left: -59px;
    }
  }
  .contentWrap {
    position: relative;
    > img {
      width: 1286px;
      margin-bottom: 50px;
      // height: 740px;
    }
    .btn {
      position: absolute;
      width: 332px;
      height: 81px;
      line-height: 81px;
      background: $main-blue;
      color: $main-white;
      font-size: 26px;
      right: 39px;
      bottom: 99px;
      border-radius: 40px;
      text-align: center;
      &:hover {
        background: $main-blueDark;
      }
    }
  }
}
</style>

